"use client"

import Link from "next/link"
import { HeadphonesIcon } from "lucide-react"

import { Button } from "@/components/ui/button"
import { DropdownMenuItem } from "@/components/ui/dropdown-menu"

interface SupportLinkProps {
  supportLink?: string
}

const defaultSupportUrl = "http://blattnertech.haloitsm.com/portal"

export function SupportButton({ supportLink }: SupportLinkProps) {
  return (
    <Link
      target="_blank"
      href={`mailto:${supportLink}`}
      className="flex h-9 items-center justify-center rounded-lg border-none bg-primary px-4 py-2 text-primary-foreground shadow hover:bg-primary/90 hover:text-primary"
    >
      Customer Support
    </Link>
  )
}

export function SupportLink({ supportLink }: SupportLinkProps) {
  return (
    <Link
      target="_blank"
      href={`mailto:${supportLink}`}
      className="hover:text-gray-900 dark:hover:text-gray-100"
    >
      Customer Support
    </Link>
  )
}

export function SupportDropdownMenuItem({ supportLink }: SupportLinkProps) {
  return (
    <DropdownMenuItem
      onClick={() => window.open(supportLink, "_blank")}
      className="flex items-center"
    >
      <HeadphonesIcon className="mr-2 h-4 w-4" />
      <span>Support Portal</span>
    </DropdownMenuItem>
  )
}
