"use client"

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import Link from "next/link"
import { useRouter } from "next/navigation"
import { useUser } from "@auth0/nextjs-auth0/client"
import { loadStripe } from "@stripe/stripe-js"
import {
  CheckCircle2,
  Info,
  MonitorIcon,
  SmartphoneIcon,
  X,
} from "lucide-react"

import { Button } from "@/components/ui/button"
import { Card, CardContent, CardFooter, CardHeader } from "@/components/ui/card"
import { Dialog, DialogContent } from "@/components/ui/dialog"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip"

import { useConfig } from "./config-component"

const imageCache: { [key: string]: string } = {}

interface MarketingFeature {
  name: string
}

interface ProductCardProps {
  key: string | number
  product: {
    id: string | number
    name: string
    description: string
    images: string[]
    marketing_features?: MarketingFeature[]
    capabilities?: {
      keyword: string
      description: string
    }[]
    metadata: {
      category?: string
      agents_available?: string
      sites?: string
      current_agent_actions?: string
      setup_price_id?: string
      sort_order?: string
    }
    prices: {
      id: string
      currency: string
      unit_amount: number
      recurring: { interval: string }
    }[]
  }
  productUrl: string
  isSubscribed: boolean
  isCancelled: boolean
  isPaymentPending: boolean
}

export default function ProductCard({
  product,
  productUrl,
  isSubscribed,
  isCancelled,
  isPaymentPending,
}: ProductCardProps) {
  const [isLoading, setIsLoading] = useState(false)
  const { NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY } = useConfig()
  const [imageSrc, setImageSrc] = useState<string | null>(null)
  const [productDetailUrl, setProductDetailUrl] = useState<string | null>(null)
  const { user, isLoading: isUserLoading } = useUser()
  const router = useRouter()
  const [openTooltipIndex, setOpenTooltipIndex] = useState<number | null>(null)
  const [openDialog, setOpenDialog] = useState<number | null>(null)

  useEffect(() => {
    const imageUrl = product.images[0]
    const productDetailUrl = productUrl
    if (imageUrl) {
      if (imageCache[imageUrl]) {
        setImageSrc(imageCache[imageUrl])
      } else {
        const img = new Image()
        img.src = imageUrl
        img.onload = () => {
          imageCache[imageUrl] = imageUrl
          setImageSrc(imageUrl)
        }
      }
    }
    if (productDetailUrl) {
      setProductDetailUrl(productDetailUrl)
    } else {
      setProductDetailUrl(`/marketplace/${product.id}`)
    }
  }, [product.images, productUrl])

  const handleSubscribe = async () => {
    const checkoutInfo = {
      priceId: product.prices[0].id,
      productId: String(product.id),
      timestamp: new Date().getTime(),
      returnTo: encodeURIComponent(window.location.pathname),
      setupPriceId: product.metadata.setup_price_id
        ? product.metadata.setup_price_id
        : "",
    }
    if (!user && !isUserLoading) {
      router.push(
        `/products/${product.id}?tab=pricing&returnUrl=${encodeURIComponent(window.location.pathname)}`
      )
      return
    } else {
      router.push(
        `/marketplace/${product.id}?tab=pricing&returnUrl=${encodeURIComponent(window.location.pathname)}`
      )
      return
    }
    // User is logged in, proceed with checkout
    await processCheckout(checkoutInfo)
  }
  const processCheckout = async (checkoutInfo: {
    priceId: string
    productId: string
    setupPriceId?: string
  }) => {
    setIsLoading(true)
    try {
      const response = await fetch("/api/stripe/create-checkout-session", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          priceId: checkoutInfo.priceId,
          productId: checkoutInfo.productId,
          setupPriceId: checkoutInfo.setupPriceId,
        }),
      })

      if (!response.ok) {
        throw new Error("Network response was not ok")
      }

      const { sessionId } = await response.json()

      // Clear stored checkout info
      localStorage.removeItem("pendingCheckout")

      const stripe = await loadStripe(NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY!)
      if (stripe) {
        const { error } = await stripe.redirectToCheckout({ sessionId })
        if (error) {
          console.error("Stripe Checkout Error:", error)
        }
      } else {
        console.error("Failed to load Stripe")
      }
    } catch (error) {
      console.error("Error:", error)
      // Handle error (e.g., show error message to user)
    } finally {
      setIsLoading(false)
    }
  }
  // Check for pending checkout on component mount
  useEffect(() => {
    // Only check if we haven't checked before and user is loaded
    if (user && !isUserLoading) {
      const pendingCheckout = localStorage.getItem("pendingCheckout")
      if (pendingCheckout) {
        try {
          const checkoutInfo = JSON.parse(pendingCheckout)
          const timestamp = checkoutInfo.timestamp
          const now = new Date().getTime()

          // Only process if the checkout is less than 1 hour old
          if (now - timestamp < 3600000) {
            processCheckout(checkoutInfo)
          } else {
            // Clear expired checkout info
            localStorage.removeItem("pendingCheckout")
          }
        } catch (error) {
          console.error("Error processing pending checkout:", error)
          localStorage.removeItem("pendingCheckout")
        }
      }
    }
  }, [user, isUserLoading])
  return (
    <Card className="flex h-full flex-col overflow-hidden p-6">
      <CardHeader className="p-0">
        <div className="flex h-24 items-center justify-center p-0">
          {imageSrc ? (
            /* eslint-disable-next-line @next/next/no-img-element */
            <img
              src={imageSrc}
              alt={product.name}
              className="max-h-12 max-w-full object-contain"
            />
          ) : (
            <div className="h-12 w-full" />
          )}
        </div>
      </CardHeader>
      <CardContent className="flex-grow pb-6 pt-3">
        <h2 className="mb-2 text-2xl font-bold text-black">{product.name}</h2>
        <p className="mb-4 text-gray-600">{product.description}</p>
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            <ul className="space-y-2 text-sm">
              {product.capabilities?.map((capability, index) => (
                <li key={index} className="flex items-center text-sm">
                  <CheckCircle2 className="mr-2 h-4 w-4 flex-shrink-0 text-primary" />
                  <span>{capability.keyword}</span>
                  {capability.description !== "" && (
                    <>
                      {/* Desktop Tooltip */}
                      <div className="-mb-1 hidden md:block">
                        <TooltipProvider>
                          <Tooltip open={openTooltipIndex === index}>
                            <TooltipTrigger
                              className="cursor-help"
                              onMouseEnter={() => setOpenTooltipIndex(index)}
                              onMouseLeave={() => setOpenTooltipIndex(null)}
                            >
                              <Info className="ml-2 h-4 w-4 flex-shrink-0 text-[#858585] hover:text-[#333333]" />
                            </TooltipTrigger>
                            <TooltipContent className="rounded-lg border border-[#D6D6D6] bg-[#E5F1FF] p-4">
                              <div className="">
                                <h3 className="font-medium text-black">
                                  {capability.keyword}
                                </h3>
                                <p className="max-w-xs text-sm text-muted-foreground">
                                  {capability.description}
                                </p>
                              </div>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </div>

                      {/* Mobile Dialog */}
                      <div className="-mb-1 md:hidden">
                        <Button
                          variant="ghost"
                          size="icon"
                          className="ml-2 h-4 w-4 border-0 p-0 hover:bg-transparent"
                          onClick={() => setOpenDialog(index)}
                        >
                          <Info className="h-4 w-4 flex-shrink-0 text-[#858585] hover:text-[#333333]" />
                        </Button>
                      </div>
                    </>
                  )}
                </li>
              ))}
            </ul>
          </div>
          {/* <div className="flex items-center space-x-2">
            <span className="text-sm font-medium">Agents Available:</span>
            <span className="text-sm bg-gray-100 px-2 py-1 rounded">
              {product.metadata.agents_available || "N/A"}
            </span>
            <span className="text-sm text-gray-500">Sites: {product.metadata.sites || "N/A"}</span>
          </div>
          <div className="flex items-center space-x-2">
            <span className="text-sm font-medium">Current Agent Actions:</span>
            <span className="text-sm bg-gray-100 px-2 py-1 rounded">
              {product.metadata.current_agent_actions || "N/A"}
            </span>
          </div> */}
        </div>
      </CardContent>
      <CardFooter className="flex items-center justify-between">
        <div className="flex w-full items-center justify-between">
          <div className="flex space-x-2">
            {!isPaymentPending && (
              <Button
                className="border-none bg-blue-600 text-white"
                onClick={handleSubscribe}
                disabled={isLoading || isUserLoading}
              >
                {isLoading
                  ? "Processing..."
                  : isSubscribed
                    ? isCancelled
                      ? "Re-Subscribe"
                      : "Upgrade"
                    : "Subscribe"}
              </Button>
            )}
            <Link
              href={`${productDetailUrl}?returnUrl=${encodeURIComponent(window.location.pathname)}`}
            >
              <Button variant="outline" className="border-none">
                Details
              </Button>
            </Link>
          </div>
          <div className="hidden space-x-2">
            <MonitorIcon className="text-gray-400" />
            <SmartphoneIcon className="text-gray-400" />
          </div>
        </div>
      </CardFooter>
      <Dialog
        open={openDialog !== null}
        onOpenChange={() => setOpenDialog(null)}
      >
        <DialogContent className="rounded-lg border border-[#D6D6D6] bg-[#E5F1FF] p-4 sm:max-w-[425px]">
          <div className="absolute right-4 top-2">
            <Button
              variant="ghost"
              size="icon"
              className="h-6 w-6 rounded-md border-0 p-0 hover:bg-transparent"
              onClick={() => setOpenDialog(null)}
            >
              <X className="h-4 w-4" />
              <span className="sr-only">Close</span>
            </Button>
          </div>
          <div className="">
            <h3 className="font-medium text-black">
              {openDialog !== null &&
                product.capabilities?.[openDialog]?.keyword}
            </h3>
            <p className="text-sm text-[#333333]">
              {openDialog !== null &&
                product.capabilities?.[openDialog]?.description}
            </p>
          </div>
        </DialogContent>
      </Dialog>
    </Card>
  )
}
